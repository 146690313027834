<template>
  <div id="member-subscription-create">
    <v-card>
      <v-card-title class="text-h5">
        <h2>Member Subscription Payment</h2>
        <v-spacer />
        <v-btn
          @click.prevent="pageData"
          color="#a4c639"
          min-width="92"
          rounded
          mediums
          class="font-size-h6 px-3 py-3 ml-3 white--text"
        >
          Refresh
        </v-btn>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>
          List of member subscription available for the year
        </h4></v-card-subtitle
      >

      <v-card-text>
        <v-container class="notes" v-if="NotesFlag">
          <p>Kindly note:</p>
          <ol>
            <li>Regular subscription can be paid only once.</li>
            <li>
              After paying regular subscription, choose additional subcription.
            </li>
            <li>
              Late fee is optional but paying late fee is required to get voting
              rights.
            </li>
            <li>
              After completing the payment, kindly select your members for whom
              the payment was made.
            </li>
          </ol>
        </v-container>
        <h6><span class="text-danger">*</span> indicates required field</h6>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container v-if="StartupLoadingFlag">
          <v-row wrap>
            <v-col cols="12" md="4" v-for="n in 6" :key="n">
              <v-skeleton-loader
                v-bind="attrs"
                type="paragraph"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="!StartupLoadingFlag">
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" md="3">
                <label><span class="text-danger">*</span>Year</label>
                <v-autocomplete
                  @change="searchForm"
                  :reduce="(option) => option.value"
                  :items="JciYearCodeOptions"
                  :rules="JciYearCodeRules"
                  :loading="JciYearCodeOptionsLoading"
                  v-model="JciYearCode"
                  hint="Select Year"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label><span class="text-danger">*</span>LOM </label>
                <h4>{{ Member.LomName }}</h4>
                <h4>{{ Member.ZoneName }}</h4>
              </v-col>
              <v-col cols="12" md="3">
                <label><span class="text-danger">*</span>President Name</label>
                <h4>{{ Member.MemberName }}</h4>
                <h4>{{ Member.MembershipId }}</h4>
              </v-col>
              <v-col cols="12" md="3">
                <v-img
                  :src="Member.MemberImagePath"
                  :alt="Member.MemberName"
                  lazy-src="/no-image-available.png"
                  max-width="100"
                  max-height="100"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" md="3">
                <h6><span class="text-danger">*</span> State Of LO</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="StateCodeOptionsLoading"
                  :items="StateCodeOptions"
                  :rules="StateCodeRules"
                  v-model="StateCode"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h6><span class="text-danger">*</span> District Of LO</h6>

                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="DistrictCodeOptionsLoading"
                  :items="DistrictCodeOptions"
                  :rules="DistrictCodeRules"
                  v-model="DistrictCode"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h6><span class="text-danger">*</span> City Of LO</h6>

                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="CityCodeOptionsLoading"
                  :items="CityCodeOptions"
                  :rules="CityCodeRules"
                  v-model="CityCode"
                  dense
                  clearable
                  required
                  outlined
                  base-color="white"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label><span class="text-danger">*</span>Mobile Number</label>
                <v-text-field
                  v-model="Member.PrimaryMobile"
                  :rules="MobileNoRules"
                  :counter="10"
                  v-mask="'##########'"
                  type="number"
                  hint="Enter your email id"
                  dense
                  outlined
                ></v-text-field>
                <!-- <h4>{{ Member.MobileNo }}</h4> -->
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label><span class="text-danger">*</span>Email Id</label>
                <v-text-field
                  v-model="Member.EmailId"
                  :rules="EmailIdRules"
                  type="email"
                  hint="Enter your email id"
                  dense
                  outlined
                ></v-text-field>
                <!-- <h4>{{ Member.EmailId }}</h4> -->
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Does your LOM has GST Number?</label>
                <v-select
                  :reduce="(option) => option.value"
                  :items="GstNumberFlagOptions"
                  :rules="GstNumberFlagRules"
                  v-model="GstNumberFlag"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="3" v-if="GstNumberFlag == 1">
                <label><span class="text-danger">*</span>GST Number</label>
                <v-text-field
                  v-model="Member.LomGstNumber"
                  :rules="GstNumberRules"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-container class="py-0" v-if="SearchFlag">
              <v-skeleton-loader
                v-bind="attrs"
                type="table-tbody"
              ></v-skeleton-loader>
            </v-container>

            <v-container class="py-0" v-if="!SearchFlag">
              <v-card>
                <v-tabs
                  v-model="tab"
                  background-color="#8950FC"
                  centered
                  dark
                  icons-and-text
                  show-arrows
                >
                  <v-tabs-slider></v-tabs-slider>

                  <v-tab href="#tab-1" :disabled="TabDisable1">
                    Subscription Payment
                    <v-icon>mdi-numeric-1-box-outline</v-icon>
                  </v-tab>

                  <v-tab href="#tab-2" :disabled="TabDisable2">
                    Late Fee Payment
                    <v-icon>mdi-numeric-2-box-outline</v-icon>
                  </v-tab>

                  <v-tab href="#tab-3" :disabled="TabDisable3">
                    Senior Members Association
                    <v-icon>mdi-numeric-3-box-outline</v-icon>
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item value="tab-1">
                    <v-card flat>
                      <p></p>
                      <v-row wrap>
                        <v-col align="center" cols="12" md="12">
                          <h3 class="text-primary">
                            List of regular subscriptions
                          </h3>
                        </v-col>
                      </v-row>
                      <v-row wrap>
                        <v-col align="center" cols="12" md="12">
                          <!-- <v-text-field
                                  v-model="search"
                                  append-icon="mdi-magnify"
                                  label="Search records here"
                                  single-line
                                  hide-details
                                ></v-text-field> -->
                          <br />
                          <v-data-table
                            class="elevation-1"
                            disable-sort
                            disable-filtering
                            v-model="selected"
                            :loading="SearchFlag"
                            loading-text="Loading... Please wait"
                            :headers="tableColumns1"
                            :items="rows"
                            :items-per-page="50"
                            :search="search"
                            item-key="YearwiseContributionId"
                            :single-select="false"
                            :show-select="false"
                            :hide-default-footer="true"
                            :footer-props="{
                              'items-per-page-options': [10, 20, 30, 40, 50],
                            }"
                          >
                            <template v-slot:item.FineCheckBox="{ item }">
                              <v-checkbox
                                v-if="item.FineFlag"
                                v-model="item.FinePaidFlag"
                                :label="item.FineAmountTxt"
                                color="info"
                                :value="item.FinePaidFlag"
                                hide-details
                                v-on="calculateTotal()"
                              ></v-checkbox>
                            </template>
                            <template v-slot:item.Qty="{ item }">
                              <v-text-field
                                v-model="item.Qty"
                                v-on="calculateTotal()"
                                type="number"
                                min="0"
                                :rules="QtyRules"
                              ></v-text-field>
                            </template>
                            <template v-slot:item.SubTotal="{ item }">
                              {{ item.SubTotal }}
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col align="center" cols="12" md="12">
                          <h3 class="text-primary">
                            Total Amount : Rs. {{ TotalAmount1 }}
                          </h3>
                          <v-checkbox
                            v-model="checkbox1"
                            :rules="checkboxRules1"
                            required
                          >
                            <template v-slot:label>
                              <div style="font-size: 14px">
                                I have read and accepted the
                                <a href="#" @click.prevent="openPage(6)"
                                  >terms and conditions</a
                                >.
                              </div>
                            </template>
                          </v-checkbox>
                          <v-btn
                            :disabled="!valid1"
                            @click.prevent="submitRegularSubscription"
                            :loading="SubmitFlag"
                            color="#8950FC"
                            elevation="30"
                            shaped
                            tile
                            large
                            class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                          >
                            Submit
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item value="tab-2">
                    <v-card flat>
                      <p></p>
                      <v-row wrap>
                        <v-col align="center" cols="12" md="12">
                          <h3 class="text-primary">
                            List of late fee subscriptions
                          </h3>
                        </v-col>
                      </v-row>
                      <v-row wrap>
                        <v-col align="center" cols="12" md="12">
                          <!-- <v-text-field
                                  v-model="search"
                                  append-icon="mdi-magnify"
                                  label="Search records here"
                                  single-line
                                  hide-details
                                ></v-text-field> -->
                          <br />
                          <v-data-table
                            class="elevation-1"
                            disable-sort
                            disable-filtering
                            v-model="selected"
                            :loading="SearchFlag"
                            loading-text="Loading... Please wait"
                            :headers="tableColumns2"
                            :items="PenaltySubscription"
                            :items-per-page="50"
                            :search="search"
                            item-key="LomPaymentId"
                            :single-select="false"
                            :show-select="false"
                            :hide-default-footer="true"
                            :footer-props="{
                              'items-per-page-options': [10, 20, 30, 40, 50],
                            }"
                          >
                            <template v-slot:item.FineCheckBox="{ item }">
                              <v-checkbox
                                v-if="item.FineFlag"
                                v-model="item.FinePaidFlag"
                                :label="item.FineAmountTxt"
                                color="info"
                                :value="item.FinePaidFlag"
                                hide-details
                                v-on="calculatePenaltyTotal()"
                              ></v-checkbox>
                            </template>
                            <template v-slot:item.Qty="{ item }">
                              <v-text-field
                                v-model="item.Qty"
                                v-on="calculatePenaltyTotal()"
                                type="number"
                                min="0"
                                :rules="QtyRules"
                              ></v-text-field>
                            </template>
                            <template v-slot:item.SubTotal="{ item }">
                              {{ item.SubTotal }}
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col align="center" cols="12" md="12">
                          <h3 class="text-primary">
                            Total Amount : Rs. {{ TotalAmount2 }}
                          </h3>
                          <v-checkbox
                            v-model="checkbox1"
                            :rules="checkboxRules1"
                            required
                          >
                            <template v-slot:label>
                              <div style="font-size: 14px">
                                I have read and accepted the
                                <a href="#" @click.prevent="openPage(6)"
                                  >terms and conditions</a
                                >.
                              </div>
                            </template>
                          </v-checkbox>
                          <v-btn
                            :disabled="!valid1"
                            @click.prevent="submitPenaltySubscription"
                            :loading="SubmitFlag"
                            color="#8950FC"
                            elevation="30"
                            shaped
                            tile
                            large
                            class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                          >
                            Submit
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-tab-item>

                  <v-tab-item value="tab-3">
                    <v-card flat height="300px">
                      <br /><br />
                      <v-row wrap>
                        <v-col align="center" cols="12" md="12">
                          <v-btn
                            href="https://seniors.jciindia.in/join-us"
                            target="_blank"
                            elevation="30"
                            shaped
                            tile
                            small
                            color="blue"
                            class="animate-button1"
                          >
                            Pay Subscription Fees for Senior Member Associan
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
              <!-- <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3>{{ rows.length }} loms found.</h3>
              </v-col>
            </v-row> -->
            </v-container>
          </v-form>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
    <v-dialog v-model="OpenPageFlag" persistent max-width="80%">
      <popup-page-content
        :addRecordPrompt="OpenPageFlag"
        :pageId="PageId"
        @hideAddRecordPrompt="hideAddRecordPrompt"
        v-if="OpenPageFlag"
      ></popup-page-content>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,
      NotesFlag: false,
      rows: [],
      tableColumns1: [],
      tableColumns2: [
        { text: "Date", value: "PaidDateTxt", width: "13%" },
        { text: "Description", value: "Description", width: "35%" },
        { text: "Late Fee", value: "FineAmountTxt", width: "13%" },
        { text: "To be paid", value: "FineNotPaid", width: "13%" },
        { text: "No of members", value: "Qty", width: "13%" },
        { text: "Sub Total", value: "SubTotal", width: "13%" },
      ],
      selected: [],
      search: "",
      CurrentYearId: 0,

      checkbox1: false,
      checkboxRules1: [(v) => !!v || "You must agree to continue!"],

      OpenPageFlag: false,
      PageId: "",

      CheckoutTableHeaders: [],

      tab: null,
      TabDisable1: false,
      TabDisable2: false,
      TabDisable3: false,

      TotalAmount: 0,
      Contribution: [],

      TotalAmount1: 0,
      TotalAmount2: 0,

      Member: {},
      Order: {},
      MemberImageFlag: false,

      PenaltySubscription: [],

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      OrderType: 1,
      MemberCityId: "",
      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      MobileNoRules: [(v) => !!v || "Mobile No is required"],

      EmailIdRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      QtyRules: [
        (v) => Number.isInteger(Number(v)) || "Nos is invalid",
        (v) => v >= 0 || "Number must be greater than zero",
      ],

      GstNumberFlag: 2,
      GstNumberFlagRules: [
        (v) => !!v || "Does your LOM has GST number field required",
      ],
      GstNumberFlagOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Yes" },
        { value: 2, text: "No" },
      ],

      GstNumber: "",
      GstNumberRules: [(v) => !!v || "GST number is required"],

      StateCodeRules: [(v) => !!v || "State is required"],
      StateCode: "",
      StateCodeOptions: [],
      StateCodeOptionsLoading: false,

      DistrictCodeRules: [(v) => !!v || "District is required"],
      DistrictCode: "",
      DistrictCodeOptions: [],
      DistrictCodeOptionsLoading: false,

      CityCodeRules: [(v) => !!v || "City is required"],
      CityCode: "",
      CityCodeOptions: [],
      CityCodeOptionsLoading: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
    };
  },
  computed: {},
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        this.YearName = YearName;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        this.pageData();
      }
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
    },
    StateCodeOptions: function () {
      console.log("watch StateCodeOptions");
      this.StateCodeOptionsLoading = false;
    },
    StateCode: function () {
      console.log("watch StateCode");
      this.getDistrictCodeOptions(
        "DistrictCode",
        "DistrictCodeOptions",
        this.StateCode
      );
    },
    DistrictCodeOptions: function () {
      console.log("watch DistrictCodeOptions");
      this.DistrictCodeOptionsLoading = false;
    },
    DistrictCode: function () {
      console.log("watch DistrictCode");
      this.getCityCodeOptions("CityCode", "CityCodeOptions", this.DistrictCode);
    },
    CityCodeOptions: function () {
      console.log("watch CityCodeOptions");
      this.CityCodeOptionsLoading = false;
    },
    CityCode: function () {
      console.log("watch CityCode");
      console.log("CityCode" + this.CityCode);
      console.log("CityId" + this.Member.CityId);
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "lom_events",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getJciYearCodeOptions();
      this.getMemberDetails();
      // this.searchForm();
    },
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/staff/list";
      var params = {};
      this.pageRedirect(params, url);
    },
    openPage(pageId) {
      console.log("openPage called " + pageId);
      // event.target.style.color = "salmon"
      this.PageId = pageId;
      this.OpenPageFlag = true;
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.OpenPageFlag = false;
    },
    resetForm() {
      this.$refs.form2.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    submitRegularSubscription() {
      console.log("submitRegularSubscription called");
      this.OrderType = 1;
      this.TotalAmount = this.TotalAmount1;
      this.Contribution = this.rows;
      this.confirmAlert();
    },
    submitPenaltySubscription() {
      console.log("submitPenaltySubscription called");
      this.OrderType = 2;
      this.TotalAmount = this.TotalAmount2;
      this.Contribution = this.PenaltySubscription;
      this.confirmAlert();
    },
    calculateTotal() {
      console.log("calculateTotal called");
      var n1 = this.rows.length;
      var totalAmount = 0;
      // console.log("n1=" + n1 + ", rows=" + JSON.stringify(this.rows));
      for (var i = 0; i < n1; i++) {
        var qty = this.rows[i]["Qty"];
        var PenaltyAmount = this.rows[i]["FineAmount"];
        var WithoutFineAmount = this.rows[i]["WithoutFineAmount"];
        var FinePaidFlag = this.rows[i]["FinePaidFlag"];
        var amount = this.rows[i]["Amount"];
        console.log(
          "i=" +
            i +
            ", qty=" +
            qty +
            ", PenaltyAmount=" +
            PenaltyAmount +
            ", WithoutFineAmount=" +
            WithoutFineAmount +
            ", FinePaidFlag=" +
            FinePaidFlag +
            ", amount=" +
            amount
        );
        amount = FinePaidFlag ? amount : WithoutFineAmount;
        PenaltyAmount = FinePaidFlag ? PenaltyAmount : 0;
        console.log("amount=" + amount + ", PenaltyAmount=" + PenaltyAmount);
        qty = !isNaN(qty) ? (qty >= 0 ? qty : 0) : 0;
        amount = !isNaN(amount) ? amount : 0;
        console.log("qty=" + qty + ", amount=" + amount);
        var subTotal = parseFloat(qty) * parseFloat(amount);
        this.rows[i]["PenaltyAmount"] = PenaltyAmount;
        this.rows[i]["PenaltyAmountTxt"] = this.getDecimalNumber(
          PenaltyAmount,
          2
        );
        this.rows[i]["SubTotal"] = isNaN(subTotal)
          ? 0
          : this.getDecimalNumber(subTotal, 2);
      }
      var totalAmount = this.rows.reduce(function (sum, line) {
        var lineTotal = parseFloat(line.SubTotal);
        console.log("lineTotal=" + lineTotal);
        if (!isNaN(lineTotal)) {
          return sum + lineTotal;
        }
      }, 0);
      console.log("totalAmount=" + totalAmount);
      this.TotalAmount1 = isNaN(totalAmount)
        ? 0
        : this.getDecimalNumber(totalAmount, 2);
    },
    calculatePenaltyTotal() {
      console.log("calculateTotal called");
      var rows = this.PenaltySubscription;
      var n1 = rows.length;
      var totalAmount = 0;
      // console.log("n1=" + n1 + ", rows=" + JSON.stringify(this.rows));
      for (var i = 0; i < n1; i++) {
        var qty = rows[i]["Qty"];
        var amount = rows[i]["FineAmount"];
        console.log("i=" + i + ", qty=" + qty + ", amount=" + amount);
        qty = !isNaN(qty) ? (qty >= 0 ? qty : 0) : 0;
        amount = !isNaN(amount) ? amount : 0;
        console.log("qty=" + qty + ", amount=" + amount);
        var subTotal = parseFloat(qty) * parseFloat(amount);
        this.PenaltySubscription[i]["SubTotal"] = isNaN(subTotal)
          ? 0
          : this.getDecimalNumber(subTotal, 2);
      }
      var totalAmount = this.PenaltySubscription.reduce(function (sum, line) {
        var lineTotal = parseFloat(line.SubTotal);
        console.log("lineTotal=" + lineTotal);
        if (!isNaN(lineTotal)) {
          return sum + lineTotal;
        }
      }, 0);
      console.log("totalAmount=" + totalAmount);
      this.TotalAmount2 = isNaN(totalAmount)
        ? 0
        : this.getDecimalNumber(totalAmount, 2);
    },
    checkout() {
      console.log("checkout");
      this.pageRedirect({}, "/members/subscription/payment/checkout");
    },
    getMemberDetails() {
      console.log("getMemberDetails is called");

      var MemberId = this.$session.get("MemberId");
      MemberId = MemberId == (null || undefined) ? "" : MemberId;

      console.log("MemberId=" + MemberId);

      if (MemberId != "") {
        this.SubmitFlag = true;
        this.StartupLoadingFlag = true;
        this.Member = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/show";
        var upload = {
          UserInterface: 1,
          MemberId: MemberId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SubmitFlag = false;
            thisIns.StartupLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              var member = records[0];
              thisIns.Member = member;
              thisIns.MemberImageFlag = true;
              thisIns.Member.EmailId =
                member.EmailId == "NA" ? "" : member.EmailId;
              thisIns.Member.PrimaryMobile =
                member.PrimaryMobile == "NA" ? "" : member.PrimaryMobile;
              // thisIns.MemberCityId = member.CityId;
              thisIns.getStateCodeOptions("StateCode", "StateCodeOptions");
              thisIns.StateCode = member.LomStateId;
              thisIns.DistrictCode = member.LomDistrictId;
              thisIns.CityCode = member.LomCityId;
              // thisIns.toast("success", output);
              // thisIns.resetForm();
              thisIns.searchForm();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            this.StartupLoadingFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.toast("error", message);
      }
    },
    getStateCodeOptions(source, destination) {
      console.log("getStateCodeOptions called");
      if (source != "" && destination != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/states/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getDistrictCodeOptions(source, destination, StateCode) {
      console.log("getDistrictCodeOptions called StateCode=" + StateCode);
      if (source != "" && destination != "" && StateCode != "") {
        this.DistrictCodeOptionsLoading = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityCodeOptions(source, destination, DistrictCode) {
      console.log("getCityCodeOptions called DistrictCode=" + DistrictCode);
      if (source != "" && destination != "" && DistrictCode != "") {
        this.CityCodeOptionsLoading = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getJciYearCodeOptions() {
      this.LoadingFlag = true;
      this.JciYearCodeOptionsLoading = true;
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm is called");
      this.getRegularSubscriptionList();
      this.getPenaltySubscriptionList();
    },
    getRegularSubscriptionList() {
      console.log("getRegularSubscriptionList is called");

      var JciYearCode = this.JciYearCode;
      console.log("JciYearCode=" + JciYearCode);

      // JciYearCode = "";

      if (JciYearCode != "") {
        this.SearchFlag = true;
        this.rows = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-contribution/lists";
        var upload = {
          UserInterface: 1,
          YearCode: JciYearCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.rows = records.TableData;
              thisIns.toast("success", output);
              // thisIns.resetForm();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.SearchFlag = false;
          });
      } else {
        var message = "";
        if (JciYearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getPenaltySubscriptionList() {
      console.log("getPenaltySubscriptionList is called");

      var LomId = this.Member.LomId;
      console.log("LomId=" + LomId);

      // LomId = "";

      if (LomId != "") {
        this.SearchFlag = true;
        this.PenaltySubscription = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-payment/lists";
        var upload = {
          UserInterface: 2,
          LomCode: LomId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              // thisIns.tableColumns1 = records.TableHeader;
              thisIns.PenaltySubscription = records;
              thisIns.toast("success", output);
              // thisIns.resetForm();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.SearchFlag = false;
          });
      } else {
        var message = "";
        if (LomId == "") {
          message += "Lom should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    confirmAlert() {
      var validate1 = this.$refs.form2.validate();
      var TotalAmount = this.TotalAmount;
      console.log("validate1=" + validate1 + ", TotalAmount=" + TotalAmount);

      // TotalAmount=0;

      if (validate1 && TotalAmount > 0) {
        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (TotalAmount == 0) {
          message += "Minimum one nos required to continue. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form2.validate();
      var TotalAmount = this.TotalAmount;
      console.log("validate1=" + validate1 + ", TotalAmount=" + TotalAmount);

      // TotalAmount=0;

      if (validate1 && TotalAmount > 0) {
        this.SubmitFlag = true;
        this.TabDisable1 = true;
        this.TabDisable2 = true;

        // Get Year name from JciYearCodeOptions

        var JciYearCode = this.JciYearCode;
        var JciYearCodeIndex = this.JciYearCodeOptions.map(
          (e) => e.value
        ).indexOf(JciYearCode);
        console.log(
          "JciYearCode=" +
            JciYearCode +
            ", JciYearCodeIndex=" +
            JciYearCodeIndex
        );
        var JciYearName = this.JciYearCodeOptions[JciYearCodeIndex].text;
        console.log("JciYearName=" + JciYearName);

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/subscription/orders/create";
        var upload = {
          YearCode: this.JciYearCode,
          OrderType: this.OrderType,
          YearName: JciYearName,
          GstNumberFlag: this.GstNumberFlag,
          GstNumber: this.Member.LomGstNumber,
          LoState: this.StateCode,
          LoDistrict: this.DistrictCode,
          LoCity: this.CityCode,
          Member: this.Member,
          Contribution: this.Contribution,
          TotalAmount: this.TotalAmount,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        // this.$session.set("checkout", upload);
        // this.checkout();

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SubmitFlag = false;
            thisIns.TabDisable1 = false;
            thisIns.TabDisable2 = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              upload["Order"] = records.order;
              // upload["Contribution"] = records.contribution;
              upload["TableHeaders"] = records.CheckoutTableHeaders;
              upload["Contribution"] = records.contribution;

              thisIns.$session.set("checkout", upload);
              thisIns.sweetAlert(
                "info",
                "You will be redirected to checkout page shortly",
                true
              );
              thisIns.checkout();
            } else {
              // thisIns.toast("error", output);
              thisIns.sweetAlert("info", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.TabDisable1 = false;
            thisIns.TabDisable2 = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (TotalAmount == 0) {
          message += "Minimum one nos required to continue. ";
        }
        this.sweetAlert("error", message, false);
        // this.toast("error", message);
      }
    },
  },
  beforeMount() {
    var CurrentYearId = this.$session.get("CurrentYearId");
    CurrentYearId = 1;
    CurrentYearId = CurrentYearId == (null || undefined) ? 0 : CurrentYearId;
    this.CurrentYearId = CurrentYearId;
    if (this.$session.has("checkout")) {
      this.$session.remove("checkout");
    }
    this.refreshPageData();
    /*
    this.tableColumns1.push(
      { text: "Description", value: "Description" },
      { text: "Subscription Fees", value: "AmountTxt" },
      { text: "Nos", value: "Qty" },
      { text: "Sub Total", value: "SubTotal" }
      // { text: "Actions", value: "actions" }
    );
    */
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#member-subscription-create {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 16px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 15px;
  }
  .animate-button1 {
    color: white;

    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
  }
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
}
</style>